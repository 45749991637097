<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsSearchResults></TsSearchResults>

	2019-06-08	init

-->

<template>
	<div class="TsSearchResults" :class="elmClasses">
		<div class="TsSearchResults__inner view__panel view__panel--both background background--lightestBgGray">

			<TsPostHeader
				class="hyphenate"
				:mainTitle="$t('title.searchResultsFor') + '<br/><span>'+queryString+'</span>'"
				:postType="'page'"
			></TsPostHeader>

			<div class="TsSearchResults__groups hSpace hSpace--content">
				<!-- news -->
				<div class="TsSearchResults__group">
					<TsSubHeadline
						class="TsSearchResults__groupTitle"
						:text="'<span>'+$t('searchResults.news')+'</span><span class=\'font font--mono\'>' + newsPosts.length + '</span>'"
					></TsSubHeadline>
					<div class="TsSearchResults__groupList richText font font--sans font--bold font--sizeMedium">
						<ul v-if="newsPosts.length">
							<li v-for="(post, index) in newsPosts" :key="'np' + index">
								<a :href="post.link" v-html="post.title.rendered"></a>
							</li>
						</ul>
						<p v-else>{{$t('message.nothingFound')}}</p>
					</div>
					<!--
					<pre name="newsPosts" maxheight>{{newsPosts}}</pre>
					-->
				</div>
				<!-- activities -->
				<div class="TsSearchResults__group">
					<TsSubHeadline
						class="TsSearchResults__groupTitle"
						:text="'<span>'+$t('searchResults.activities')+'</span><span class=\'font font--mono\'>' + activityPosts.length + '</span>'"
					></TsSubHeadline>
					<div class="TsSearchResults__groupList richText font font--sans font--bold font--sizeMedium">
						<ul v-if="activityPosts.length">
							<li v-for="(post, index) in activityPosts" :key="'ap' + index">
								<a :href="post.link" v-html="post.title.rendered"></a>
							</li>
						</ul>
						<p v-else>{{$t('message.nothingFound')}}</p>
					</div>
					<!--
					<pre name="activityPosts" maxheight>{{activityPosts}}</pre>
					-->
				</div>
				<!-- pages -->
				<div class="TsSearchResults__group">
					<TsSubHeadline
						class="TsSearchResults__groupTitle"
						:text="'<span>'+$t('searchResults.pages')+'</span><span class=\'font font--mono\'>' + pagePosts.length + '</span>'"
					></TsSubHeadline>
					<div class="TsSearchResults__groupList richText font font--sans font--bold font--sizeMedium">
						<ul v-if="pagePosts.length">
							<li v-for="(post, index) in pagePosts" :key="'pp' + index">
								<a :href="post.link" v-html="post.title.rendered"></a>
							</li>
						</ul>
						<p v-else>{{$t('message.nothingFound')}}</p>
					</div>
					<!--
					<pre name="pagePosts" maxheight>{{pagePosts}}</pre>
					-->
				</div>
				<!-- team -->
				<div class="TsSearchResults__group">
					<TsSubHeadline
						class="TsSearchResults__groupTitle"
						:text="'<span>'+$t('searchResults.team')+'</span><span class=\'font font--mono\'>' + teamPosts.length + '</span>'"
					></TsSubHeadline>
					<div class="TsSearchResults__groupList richText font font--sans font--bold font--sizeMedium">
						<ul v-if="teamPosts.length">
							<li v-for="(post, index) in teamPosts" :key="'tp' + index">
								<a v-if="'en' === app.lang" :href="_.get( app, 'acfOptions.teamPageEn.link')" v-html="post.title.rendered"></a>
								<a v-if="'de' === app.lang" :href="_.get( app, 'acfOptions.teamPageDE.link')" v-html="post.title.rendered"></a>
							</li>
						</ul>
						<p v-else>{{$t('message.nothingFound')}}</p>
					</div>
					<!--
					<pre name="teamPosts" maxheight>{{teamPosts}}</pre>
					-->
				</div>
				<!-- resources -->
				<div class="TsSearchResults__group">
					<TsSubHeadline
						class="TsSearchResults__groupTitle"
						:text="'<span>'+$t('searchResults.resources')+'</span><span class=\'font font--mono\'>' + resourcesPosts.length + '</span>'"
					></TsSubHeadline>
					<div class="TsSearchResults__groupList richText font font--sans font--bold font--sizeMedium">
						<ul v-if="resourcesPosts.length">
							<li v-for="(post, index) in resourcesPosts" :key="'rp' + index">
								<a :href="post.link" v-html="post.title.rendered"></a>
							</li>
						</ul>
						<p v-else>{{$t('message.nothingFound')}}</p>
					</div>
					<!--
					<pre name="resourcesPosts" maxheight>{{resourcesPosts}}</pre>
					-->
				</div>
				<!-- teaching -->
				<div class="TsSearchResults__group">
					<TsSubHeadline
						class="TsSearchResults__groupTitle"
						:text="'<span>'+$t('searchResults.teaching')+'</span><span class=\'font font--mono\'>' + teachingPosts.length + '</span>'"
					></TsSubHeadline>
					<div class="TsSearchResults__groupList richText font font--sans font--bold font--sizeMedium">
						<ul v-if="teachingPosts.length">
							<li v-for="(post, index) in teachingPosts" :key="'tp' + index">
								<a :href="post.link" v-html="post.title.rendered"></a>
							</li>
						</ul>
						<p v-else>{{$t('message.nothingFound')}}</p>
					</div>
					<!--
					<pre name="teachingPosts" maxheight>{{teachingPosts}}</pre>
					-->
				</div>
			</div>

			<br/><br/><br/>

			<!--
			<pre name="app.acfOptions">{{app.acfOptions}}</pre>
			<pre name="posts.length">{{_.size( posts )}}</pre>
			<pre name="posts" maxheight>{{posts}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	import TsPostHeader from '@/molecules/TsPostHeader.vue'
	import TsSubHeadline from '@/molecules/TsSubHeadline.vue'
	/*
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import TsFilterbar from '@/molecules/TsFilterbar.vue'
	import MhMasonryGrid from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhMasonryGrid/v1/MhMasonryGrid.vue'
	import TsNewsPostTeaser from '@/molecules/TsNewsPostTeaser.vue'
	*/

	export default {
		name: 'TsSearchResults',
		components: {
			TsPostHeader,
			TsSubHeadline,
		},
		mixins: [],
		props: {
			posts: { // not used, oder?
				type     : [Array, Boolean],
				default  : ()=>{ return [] },
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			queryString(){
				return this._.unescape( this.$route.params.query )
			},
			pagePosts(){
				return this._.filter( this.posts, { type : 'page' })
			},
			newsPosts(){
				return this._.filter( this.posts, { type : 'post' })
			},
			teamPosts(){
				return this._.filter( this.posts, { type : 'team_post' })
			},
			activityPosts(){
				return this._.filter( this.posts, { type : 'activity_post' })
			},
			resourcesPosts(){
				return this._.filter( this.posts, { type : 'resources_post' })
			},
			teachingPosts(){
				return this._.filter( this.posts, { type : 'teaching_post' })
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.TsSearchResults { // debug
		[showBorders2] & {}
	}
	.TsSearchResults { // layout
		height: 100%;
		width: 100%;

		&__groups {
			.grid;
			.grid--gap;
		}

		&__groupTitle {
			.TsSubHeadline__text {
				display: flex;
				justify-content: space-between;
			}
			.TsSubHeadline__text {
				//span:first-child { order: 2; }
				//span:last-child { order: 1; }
				span:last-child { display: none; }
			}
		}

		@media @mq[dt] {
			&__groups { .grid--cols-2; }
			//&__group:nth-child(1) { grid-column: span 2; }
			//&__group:nth-child(2) { grid-column: span 2; }
		}
		@media @mq[md] {
			&__groups { .grid--cols-2; }
		}
		@media @mq[sm] {
			&__groups { .grid--cols-1; }
		}
	}
	.TsSearchResults { // styling
		&__groups {
			padding-top: 0.5em;
		}
		&__group {
			padding-bottom: 0.5em;

		}
		&__groupTitle {
			padding-left: 0;
			//padding-right: 0;
			border-bottom: 2px solid;
			margin-bottom: 0.5em;

		}
		.TsSubHeadline__text {
			.font;
			.font--mono;
			font-size: 16px;
			font-weight: normal;
    		line-height: 19px;
			padding-bottom: 9px;
		}

		@media @mq[dt] {
			&__group { min-height: 10em; }
		}
		@media @mq[md] {
			&__group { min-height: 8em; }
		}
		@media @mq[sm] {
			&__group { min-height: initial; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
