<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsNewsArchive></TsNewsArchive>

	2019-06-08	init

-->

<template>
	<div class="TsNewsArchive" :class="elmClasses">
		<div class="view__panel view__panel--left background"
			 :class="$root.isBelowDt ? 'background--white' : 'background--lightBgGray'">
			<div class="TsNewsArchive__homeIntroTextWrapper"
				 :class="$root.isBelowMd ? '' : 'scroller customScrollbars'"
				 :style="{ height : $root.isBelowMd && !isFoldedHomeIntroText ? '50vh' : 'auto' }">
				<div class="TsNewsArchive__homeIntroText hSpace hSpace--content richText hyphenate font font--sans font--bold"
					:class="$root.isBelowMd ? 'background--white' : 'font--sizeMedium'"
					v-html="_.get( post, 'acf.introText' )"
				></div>
				<a class="TsNewsArchive__readMoreBtn hSpace hSpace--content"
				   :class="isFoldedHomeIntroText ? 'TsNewsArchive__readMoreBtn--readLess' : 'TsNewsArchive__readMoreBtn--readMore'"
				   role="button"
				   @click="isFoldedHomeIntroText = !isFoldedHomeIntroText"
				   v-html="isFoldedHomeIntroText ? $t('button.readLess') : $t('button.readMore')"
				></a>
			</div>
		</div>
		<div class="view__panel view__panel--right background background--lightestBgGray">
			<TsPostHeader
				:mainCategories="false"
				:overTitle="''"
				:mainTitle="'News'"
			></TsPostHeader>

			<MhMasonryGrid
				class="TsNewsArchive__posts hSpace hSpace--content reset reset--list"
				:gridRowHeight="'10px'"
				:contentSelector="'.TsNewsPostTeaser__inner'"
				:tag="'ol'"
				:doLayout="posts">
				<TsNewsPostTeaser
					v-for="(newsPost, index) in posts" :key="index"
					:tag="'li'"
					:post="newsPost"
				></TsNewsPostTeaser>
			</MhMasonryGrid>

			<!--
			<ol class="TsNewsArchive__posts hSpace hSpace--content reset reset--list">
				<TsNewsPostTeaser
					v-for="(newsPost, index) in posts"
					:key="index"
					:tag="'li'"
					:post="newsPost"
				></TsNewsPostTeaser>
			</ol>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import TsPostHeader from '@/molecules/TsPostHeader.vue'
	import MhMasonryGrid from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhMasonryGrid/v1/MhMasonryGrid.vue'
	import TsNewsPostTeaser from '@/molecules/TsNewsPostTeaser.vue'

	export default {
		name: 'TsNewsArchive',
		components: {
			TsPostHeader,
			MhMasonryGrid,
			TsNewsPostTeaser,
		},
		mixins: [
			RestHandler,
		],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			post: {
				type     : [Object, Boolean],
				default  : false,
			},
		},
		data(){
			return {
				posts               : undefined,
				isReady             : false,
				isFoldedHomeIntroText : false,
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			fetch(){ // fetch news
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'post',
						perPage  : -1,
						lang     : this.app.lang,
					},
					callback : (response) => {
						this.posts   = response.data.result
						this.isReady = true
					},
				})
			}
		},
		created(){},
		mounted(){
			this.fetch()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.TsNewsArchive { // debug
		[showBorders2] & {}
	}
	.TsNewsArchive { // layout
		&__posts {
			.grid;
			.grid--gap;

			@media @mq[dt] { .grid--cols-2; }
		}
	}
	.TsNewsArchive { // styling
		&__homeIntroTextWrapper {
			position: relative;
			height: 100vh;

			@media @mq[sm] { height: 50vh; }
			@media @mq[sm] { overflow: hidden; }
		}
		&__homeIntroText {
			padding-top: 23px;
			padding-bottom: 1em;

			@media @mq[md] { font-size: 15px; line-height: 19px; }
			@media @mq[sm] { padding-bottom: 3em; }
		}
		&__readMoreBtn {
			position: absolute;
			left: 0; right: 0; bottom: 0;
			padding-top: 1em;
			padding-bottom: 1.25em;
			font-size: 10px;
			font-weight: bold;
			color: inherit;
			text-decoration: underline;
			background-color: white;

			@media @mq[dt] { display: none; }
			@media @mq[md] { display: none; }
			@media @mq[sm] { display: block; }

			&:before { // white shadow above
				position: absolute;
				top: -5em; left: 0; right: 0;
				height: 5em;
				content: "";

				background: linear-gradient(0deg, rgba(249,249,249,1) 0%, rgba(255,255,255,0) 100%);
			}
			&--readLess&:before { display: none; } // hide shadow if open
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
