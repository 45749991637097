<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsTeamList></TsTeamList>

	2019-06-08	init

-->

<template>
	<div class="TsTeamList contentModule hSpace hSpace--content" :class="elmClasses" data-dev-name="TsTeamList">
		<div class="TsTeamList__inner contentModule__inner">
			<div class="TsTeamList__item"
				 v-for="(item, index) in items"
				 :key="index">
				<div class="TsTeamList__itemHeader noSelect">
					<h4 class="TsTeamList__itemTitle"
						role="button"
						@click="doToggleItem( index )">
						<span class="TsTeamList__itemTitleText">
							<span class="TsTeamList__itemTitleTextName block font font--sans font--bold" :class="$root.isBelowDt ? 'font--sizeMedium' : 'font--sizeDefault'">{{item.title}}</span>
							<span class="TsTeamList__itemTitleTextInstitution block font font--mono font--sizeSmall">{{item.institution}}</span>
						</span>
						<span class="TsTeamList__itemTitleDots">
							<span
								class="coloredDot font font--sizeLarge"
								v-for="(mainCategory, index) in item.mainCategories"
								:key="'d'+index"
								:style="{
									color : _.get( app.getMainCategoryById( mainCategory ), 'acf.color' )
								}"
							></span>
						</span>
					</h4>
				</div>
				<div class="TsTeamList__itemBody" v-show-slide="getIsActive( index )">
					<div class="TsTeamList__itemBodyInner">
						<MhImage class="TsTeamList__itemImage" :imageObject="item.image"></MhImage>
						<div class="TsTeamList__itemText richText hyphenate font font--mono font--sizeSmall" v-html="item.text"></div>
					</div>
				</div>
				<span class="line"></span>
			</div>
		</div>
		<!--
		<pre name="items">{{items}}</pre>
		<pre name="posts">{{posts}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import MhImage from '@/components/MhImage/MhImage.vue'

	export default {
		name: 'TsTeamList',
		components: {
			MhImage,
		},
		mixins: [
			RestHandler
		],
		props: {},
		data(){
			return {
				posts       : undefined,
				isReady     : false,
				activeItems : [],
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			items(){
				const posts = this.posts
				let items = []

				this._.forEach( posts, (post)=>{
					const postLang = this._.get( post, 'polylang.lang' )
					const item = {
						title          : this._.get( post, 'title.rendered' ),
						institution    : this._.get( post, 'acf.institution' ),
						mainCategories : this._.get( post, 'acf.mainCategories' ),
						image          : this._.get( post, 'acf.portrait' ),
						text           : this._.get( post, 'acf.text' ),
					}

					//if( postLang == this.app.lang )
					items.push( item )
				})

				return items
			}
		},
		methods: {
			fetch(){ // fetch news
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'team_post',
						perPage  : -1,
						lang     : this.app.lang,
					},
					callback : (response) => {
						this.posts   = response.data.result
						this.isReady = true
					},
				})
			},
			doToggleItem( index, doLog = false ){
				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• doToggleItem()' )
					console.log('index:', index)
				}

				if( this.activeItems.includes( index ) ){
					if( doLog ) console.log('includes > remove:')
					this.activeItems = this._.without( this.activeItems, index )
				}
				else{
					if( doLog ) console.log('!includes > add:')
					this.activeItems.push( index )
				}

				if( doLog ){
					console.groupEnd()
				}
			},
			getIsActive( index ){
				return this.activeItems.includes( index )
			},
		},
		created(){},
		mounted(){
			this.fetch()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.TsTeamList { // debug
		[showBorders2] & {}
	}
	.TsTeamList { // layout
		&__itemTitle {
			display: flex;
			//align-items: center;
			justify-content: space-between;
		}
		&__itemTitleDots {
			display: flex;
			height: 100%;
			//flex-grow: 1;
			align-items: flex-start;
		}
		&__itemImage {
			@media @mq[dt] { width: 30%; }
		}
		&__itemText {
			flex-grow: 1;
		}
	}
	.TsTeamList { // styling
		.coloredDot {
			@media @mq[md] { height: 15px; width: 15px; }
		}

		&__itemHeader {
			cursor: pointer;
		}
		&__itemTitleDots {
			padding-top: 0.3em;
		}

		&__itemTitle {
			padding-bottom: 10px;

			@media @mq[md] { padding-bottom: 8px; }
		}
		&__item + &__item &__itemTitle {
			padding-top: 10px;

			@media @mq[md] { padding-top: 8px; }
		}

		&__itemBodyInner {
			//padding-top: 15px;
			padding-bottom: 15px;
			display: flex;
			@media @mq[md] { flex-direction: column; }
		}
		&__itemImage {
			@media @mq[dt] { margin-right: 15px; }
			margin-bottom: 10px;
			flex-shrink: 0;
		}
		&__itemTitleTextInstitution {
			@media @mq[md] { font-size: 14px; }
		}
		&__itemText {
			@media @mq[md] { font-size: 14px; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
