<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsAdjacentLinks></TsAdjacentLinks>

	2019-06-08	init

-->

<template>
	<div class="TsAdjacentLinks contentModule hSpace hSpace--content" :class="elmClasses" v-if="hasLinks" data-dev-name="TsAdjacentLinks">
		<div class="TsAdjacentLinks__inner contentModule__inner richText">
			<a class="hideIfEmpty font font--sans font--bold font--sizeMini"
			   :href="_.get( links, 'previous.url' )"
			   v-html="_.get( links, 'previous.title' )"
			></a>
			<a class="hideIfEmpty font font--sans font--bold font--sizeMini"
			   :href="_.get( links, 'next.url' )"
			   v-html="_.get( links, 'next.title' )"
			></a>
		</div>
		<!--
		<pre name="$attrs">{{$attrs}}</pre>
		<pre name="links">{{links}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'TsAdjacentLinks',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			links: {
				type     : [Object, Boolean],
				default  : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			hasLinks(){
				const prevTitle = this._.get( this.links, 'previous.title', false )
				const nextTitle = this._.get( this.links, 'previous.title', false )

				return prevTitle || nextTitle ? true : false
			}
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.TsAdjacentLinks { // debug
		[showBorders2] & {}
	}
	.TsAdjacentLinks { // layout
		&__inner {
			display: flex;
			justify-content: space-between;
		}
	}
	.TsAdjacentLinks { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
