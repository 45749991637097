<!--

	Diese Componente verwendet ein normales CSS-Grid
	um ein Masonry-Grid zu imitieren.
	Siehe: https://medium.com/@andybarefoot/a-masonry-style-layout-using-css-grid-8c663d355ebb

	Welche $props gibt es?

	Beispiel Code:
		<MhMasonryGrid></MhMasonryGrid>

	2019-06-08	init

-->

<template>
	<div class="MhMasonryGrid" :class="elmClasses" :style="gridElmStyles" :is="tag">
		<!--
		<div class="MhMasonryGrid__trashItem">
			<div class="MhMasonryGrid__trashItemInner content TsNewsPostTeaser__inner">
				1 <br/>
				• <br/>
				• <br/>
			</div>
		</div>
		<div class="MhMasonryGrid__trashItem">
			<div class="MhMasonryGrid__trashItemInner content TsNewsPostTeaser__inner">
				2 <br/>
				• <br/>
				• <br/>
				• <br/>
			</div>
		</div>
		<div class="MhMasonryGrid__trashItem">
			<div class="MhMasonryGrid__trashItemInner content TsNewsPostTeaser__inner">
				3 <br/>
				• <br/>
				• <br/>
				• <br/>
				• <br/>
				• <br/>
				• <br/>
			</div>
		</div>
		<div class="MhMasonryGrid__trashItem">
			<div class="MhMasonryGrid__trashItemInner content TsNewsPostTeaser__inner">
				4 <br/>
				• <br/>
				• <br/>
				• <br/>
			</div>
		</div>
		<div class="MhMasonryGrid__trashItem">
			<div class="MhMasonryGrid__trashItemInner content">
				5 <br/>
				• <br/>
				• <br/>
			</div>
		</div>
		<div class="MhMasonryGrid__trashItem">
			<div class="MhMasonryGrid__trashItemInner content TsNewsPostTeaser__inner">
				6 <br/>
				• <br/>
				• <br/>
			</div>
		</div>
		-->

		<slot></slot>
		<!--
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'MhMasonryGrid',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			tag: {
				type     : [String],
				default  : 'div',
				required : false,
			},
			gridRowHeight: {
				type     : [String],
				default  : '50px',
				required : false,
			},
			contentSelector: {
				type     : [String],
				default  : '.content',
				required : false,
			},
			doLayout: [String, Object, Number, Array, Boolean],
		},
		data(){
			return {}
		},
		watch: {
			doLayout: {
				handler: function( to, from ){
					this.$nextTick(()=>{
						this.resizeAllGridItems()
					})
				},
				immediate : false,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			gridElmStyles(){
				let styles = {}

				styles.gridAutoRows = this.gridRowHeight

				return styles
			},
		},
		methods: {
			resizeGridItem( itemElm, doLog = false ){
				const gridElm = this.$el
				const gridRowGap = parseInt( window.getComputedStyle( gridElm ).getPropertyValue('grid-row-gap') )
				const gridRowHeight = parseInt( this.gridRowHeight )
				const itemInnerElm = itemElm.querySelector( this.contentSelector )

				// unset itemElm which was eventully set before
				itemElm.style.gridRowEnd = ''
				// set height to auto to get the real size
				itemInnerElm.style.height = 'auto'

				const itemInnerElmHeight = itemInnerElm ? itemInnerElm.getBoundingClientRect().height : undefined
				const rowSpan = Math.ceil(
					(itemInnerElmHeight+gridRowGap) / (gridRowHeight+gridRowGap)
				)

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• resizeGridItem( itemElm )' )
					console.log('gridElm:', gridElm)
					console.log('gridRowGap:', gridRowGap)
					console.log('gridRowHeight:', gridRowHeight)
					console.log('--------')
					console.log('itemElm:', itemElm)
					console.log('itemInnerElm:', itemInnerElm)
					console.log('itemInnerElmHeight:', itemInnerElmHeight)
					console.log('--------')
					console.log('rowSpan:', rowSpan)
					console.groupEnd()
				}

				itemElm.setAttribute('elmHeight', itemInnerElmHeight)
				itemElm.style.gridRowEnd = "span "+rowSpan

				// unset previous set height
				itemInnerElm.style.height = ''

				this.$nextTick(()=>{ })
				this.$nextTick(()=>{
					window.requestAnimationFrame(()=>{
					})
				})
			},
			resizeAllGridItems( doLog = false ){
				const $el = this.$el
				const gridItemElms = this._.filter( $el.childNodes, (node)=>{ return node.nodeType === 1 })

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• resizeAllGridItems()' )
					console.log('$el:', $el)
					console.log('gridItemElms:', gridItemElms)
					console.groupEnd()
				}

				this.$el.style.gridAutoRows = ''
				gridItemElms.forEach(( item )=>{
					this.resizeGridItem( item )
				})
				this.$el.style.gridAutoRows = this.gridRowHeight
			},
			onResize(){
				this.resizeAllGridItems()
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.onResize, 250 ))
			window.addEventListener('resize', this._.throttle( this.onResize, 250 ))
		},
		mounted( doLog = true ){
			this.resizeAllGridItems()
		},
		destroyed(){
			window.removeEventListener('resize', this.onResize)
		}
	}
</script>

<style lang="less">
	.MhMasonryGrid { // debug
		[showBorders4] & {
			background-color: fade( red, 10 );
			& > * { outline: 1px solid red; background-color: fade( red, 10 ); }
		}

		//&__trashItem { outline: 1px solid red; }
		//&__trashItemInner { background-color: fade( red, 10 ); }
	}
	.MhMasonryGrid { // layout
		display: grid;
		//grid-template-columns: repeat(2, 1fr);
	}
	.MhMasonryGrid { // styling
		//padding: 1em;
		//background-color: fade( red, 10 );
	}
</style>
