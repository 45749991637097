<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsTextCards></TsTextCards>

	2019-06-08	init

-->

<template>
	<div class="TsTextCards contentModule hSpace hSpace--content" :class="elmClasses" data-dev-name="TsTextCards">
		<!--
		<MhMasonryGrid
			class="TsTextCards__inner contentModule__inner"
			:gridRowHeight="'10px'"
			:contentSelector="'.TsTextCards__cardInner'"
			:tag="'div'"
			:doLayout="textCards">
			<div class="TsTextCards__card" v-for="(card, index) in textCards" :key="index">
				<div class="TsTextCards__cardInner">
					<h5 class="TsTextCards__cardTextAbove font font--mono font--sizeSmall" v-html="card.textAbove"></h5>
					<span class="TsTextCards__cardLine line"></span>
					<p class="TsTextCards__cardTextBelow hyphenate font font--sans font--bold font--sizeDefault" v-html="card.textBelow"></p>
				</div>
			</div>
		</MhMasonryGrid>
		-->

		<div class="TsTextCards__inner contentModule__inner">
			<div class="TsTextCards__card" v-for="(card, index) in textCards" :key="index">
				<div class="TsTextCards__cardInner">
					<h5 class="TsTextCards__cardTextAbove font font--mono font--sizeSmall" v-html="card.textAbove"></h5>
					<span class="TsTextCards__cardLine line"></span>
					<p class="TsTextCards__cardTextBelow hyphenate font font--sans font--bold font--sizeDefault" v-html="card.textBelow"></p>
				</div>
			</div>
		</div>
		<!--
		-->

		<!--
		<pre name="$attrs">{{$attrs}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import MhMasonryGrid from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhMasonryGrid/v1/MhMasonryGrid.vue'

	export default {
		name: 'TsTextCards',
		components: {
			MhMasonryGrid,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			textCards: {
				type     : [Array, Boolean],
				default  : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			handleScroll( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.TsTextCards { // debug
		[showBorders2] & {}
	}
	.TsTextCards { // layout
		&__inner {
			.grid;
			.grid--gap;

			@media @mq[dt] { .grid--cols-2; }
		}
	}
	.TsTextCards { // styling
		&__card {
			&TextAbove {
				padding-bottom: 9px;

				@media @mq[md] {
					padding-bottom: 2px;
					font-size: 14px;
				}
			}
			&Line {
				padding-bottom: 11px;

				@media @mq[md] {
					padding-bottom: 5px;
				}
			}
			&TextBelow {
				word-break: break-word; // needed for long german words in en text that cant be hyphenated
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
