<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsAkkordeon></TsAkkordeon>

	2019-06-08	init

-->

<template>
	<div class="TsAkkordeon contentModule hSpace hSpace--content" data-dev-name="TsAkkordeon" :class="elmClasses">
		<div class="TsAkkordeon__inner contentModule__inner">
			<div class="TsAkkordeon__item"
				 v-for="(akkordeonItem, index) in akkordeon"
				 :key="index">
				<div class="TsAkkordeon__itemHeader noSelect font font--sans font--bold font--sizeLarge">
					<h4 class="TsAkkordeon__itemTitle"
						role="button"
						@click="doToggleAkkordeonItem( index )">
						{{akkordeonItem.title}}
						<!--
						{{getIsActive( index )}}
						-->
						<span class="coloredDot"
							v-if="akkordeonItem.mainCategory"
							:style="{ color : _.get( app.getMainCategoryById( akkordeonItem.mainCategory ), 'acf.color' ) }"
						></span>
					</h4>
					<span class="line"></span>
				</div>
				<div class="TsAkkordeon__itemBody" v-show-slide="getIsActive( index )">
					<div class="TsAkkordeon__itemBodyInner">
						<MhImage class="TsAkkordeon__itemImage" :imageObject="akkordeonItem.image" aria-hidden="true"></MhImage>
						<div class="TsAkkordeon__itemText richText hyphenate font font--sans font--bold font--sizeDefault" v-html="akkordeonItem.text"></div>
					</div>
				</div>
			</div>
		</div>
		<!--
		<pre name="activeItems">{{activeItems}}</pre>
		<pre name="mainCategory">{{akkordeonItem.mainCategory}}</pre>
		<pre name="image">{{akkordeonItem.image}}</pre>
		<pre name="text">{{akkordeonItem.text}}</pre>
		<pre name="$props">{{$props}}</pre>
		<pre name="$attrs">{{$attrs}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '@/components/MhImage/MhImage.vue'

	export default {
		name: 'TsAkkordeon',
		components: {
			MhImage,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			akkordeon: {
				type     : [Array, Boolean],
				default  : false,
			},
		},
		data(){
			return {
				activeItems : [],
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			doToggleAkkordeonItem( index, doLog = false ){
				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• doToggleAkkordeonItem()' )
					console.log('index:', index)
				}

				if( this.activeItems.includes( index ) ){
					if( doLog ) console.log('includes > remove:')
					this.activeItems = this._.without( this.activeItems, index )
				}
				else{
					if( doLog ) console.log('!includes > add:')
					this.activeItems.push( index )
				}

				if( doLog ){
					console.groupEnd()
				}
			},
			getIsActive( index ){
				return this.activeItems.includes( index )
			},
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){
			//console.log('this:', this)
		},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.TsAkkordeon { // debug
		[showBorders2] & {}
	}
	.TsAkkordeon { // layout
		&__itemTitle {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.TsAkkordeon { // styling
		.coloredDot {
			@media @mq[md] { height: 15px; width: 15px; }
		}
		&__itemHeader {
			cursor: pointer;
		}
		&__itemTitle {
			padding-top: 15px;
			padding-bottom: 15px;

			@media @mq[md] { padding-top: 8px; padding-bottom: 8px; }
		}
		&__itemBodyInner {
			padding-top: 15px;
			padding-bottom: 30px;

			@media @mq[md] { padding-top: 13px; padding-bottom: 20px; }
		}
		&__itemImage {
			margin-bottom: 0.5em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
