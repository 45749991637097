<template>
	<MhRouterView class="PageView view" :class="elmClasses" :isContentReady="isContentReady">

		<!--
		<div class="flex font font--sizeMini">
			<pre name="lang">{{app.lang}}</pre>
			<pre name="$route.params.slug">{{$route.params.slug}}</pre>
			<pre name="postSlug">{{postSlug}}</pre>
			<pre name="is404">{{is404}}</pre>
			<pre name="postTemplate">{{postTemplate}}</pre>
			<pre name="acf.template">{{_.get( post, 'acf.template' )}}</pre>
		</div>
		-->

		<template v-if="is404">
			<Ts404Message class="view__inner"></Ts404Message>
		</template>
		<template v-else-if="'home' === postTemplate">
			<TsNewsArchive class="view__inner"
				:post="post"
			></TsNewsArchive>
		</template>
		<template v-else-if="'team' === postTemplate">
			<div class="view__inner">
				<div class="view__panel view__panel--left">
					<TsGallery class="background background--lightBgGray" :images="_.get( post, 'acf.detailGallery' )"></TsGallery>
				</div>
				<div class="view__panel view__panel--right">
					<TsPostHeader
						:mainTitle="_.get( post, 'title.rendered' )"
						:postType="_.get( post, 'type' )"
					></TsPostHeader>

					<TsTeamList :posts="teamPosts"></TsTeamList>
				</div>
			</div>
		</template>
		<template v-else-if="'teaching' === postTemplate">
			<TsTeachingArchive class="view__inner"
				:post="post"
			></TsTeachingArchive>
		</template>
		<template v-else-if="'custom' === postTemplate || 'contact' === postTemplate">
			<div class="view__inner" v-if="post">
				<!--
				<pre name="$mq">{{$mq}}</pre>
				<pre name="isBelowDt">{{$root.isBelowDt}}</pre>
				<pre name="post.type">{{_.get( post, 'type' )}}</pre>
				-->

				<!-- gallery for news-posts is inline below dt, see below -->
				<div class="view__panel view__panel--left"
					 v-if="(['post', 'resources_post', 'teaching_post', 'activity_post'].includes( post.type ) || 'contact' === postTemplate) && $root.isBelowDt ? false : true">
					<TsGallery
						class="background background--lightBgGray"
						:images="_.get( post, 'acf.detailGallery' )"
					></TsGallery>
				</div>

				<div class="view__panel view__panel--right"
					:class="{'background background--yellow' : 'contact' === postTemplate }">
					<TsPostHeader
						:mainCategories="_.get( post, 'acf.mainCategories' )"
						:overTitle="_.get( post, 'acf.overTitle' )"
						:mainTitle="_.get( post, 'title.rendered' )"
						:postType="_.get( post, 'type' )"
					></TsPostHeader>

					<!-- gallery for news-posts is inline below dt -->
					<div class="hSpace hSpace--content"
						 v-if="['post', 'resources_post', 'teaching_post', 'activity_post'].includes( post.type ) && $root.isBelowDt">
						<TsGallery
							class="background background--lightBgGray"
							v-if="_.size( _.get( post, 'acf.detailGallery' ) )"
							:images="_.get( post, 'acf.detailGallery' )"
						></TsGallery>
					</div>

					<TsContentModules :modules="contentModules"></TsContentModules>

					<!-- TsAdjacentLinks -->
					<!-- news -->
					<template v-if="_.get( post, 'type' ) === 'post'">
						<TsAdjacentLinks
							:links="{
								previous : {
									//title : 'zurück zur Startseite',
									title : this.$t('button.backToHome'),
									url : '/' + this.app.lang + '/'
								}
							}"
						></TsAdjacentLinks>
					</template>
					<!-- resources_post -->
					<template v-else-if="_.get( post, 'type' ) === 'resources_post'">
						<TsAdjacentLinks
							:links="{
								previous : {
									//title : 'zurück zu Resources',
									title : this.$t('button.backToRessources'),
									url : '/' + this.app.lang + '/resources/'
								}
							}"
						></TsAdjacentLinks>
					</template>
					<!-- teaching_post -->
					<template v-else-if="_.get( post, 'type' ) === 'teaching_post'">
						<TsAdjacentLinks
							:links="{
								previous : {
									//title : 'zurück zu Teaching',
									title : this.$t('button.backToTeaching'),
									url : '/' + this.app.lang + '/teaching/'
								}
							}"
						></TsAdjacentLinks>
					</template>
					<!-- activity_post -->
					<template v-else-if="_.get( post, 'type' ) === 'activity_post'">
						<TsAdjacentLinks
							:links="{
								previous : {
									//title : 'zurück zu Activities',
									title : this.$t('button.backToActivities'),
									url : '/' + this.app.lang + '/activities/'
								}
							}"
						></TsAdjacentLinks>
					</template>
					<!-- all others -->
					<template else>
						<TsAdjacentLinks
							:links="_.get( post, 'acf.adjacentLinks' )"
						></TsAdjacentLinks>
					</template>

					<!--
					<pre name="post">{{post}}</pre>
					-->
				</div>
			</div>
		</template>
		<template v-if="'resources' === postTemplate">
			<TsResourcesArchive class="view__inner"
				:post="post"
			></TsResourcesArchive>
		</template>
		<template v-else-if="'activities' === postTemplate">
			<TsActivitiesArchive class="view__inner"
				:post="post"
			></TsActivitiesArchive>
		</template>
		<template v-if="'SearchView' === $route.name">
			<!--
			<pre name="searchResults.length">{{_.size( searchResults )}}</pre>
			<pre name="searchResults">{{searchResults}}</pre>
			-->
			<TsSearchResults class="view__inner"
				:posts="searchResults"
			></TsSearchResults>
		</template>

		<!--
		<pre name="post">{{post}}</pre>
		-->

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import MhRouterView from '@/components/MhRouterView/v2/MhRouterView.vue'

	import TsNewsArchive from '@/organisms/TsNewsArchive.vue'
	import TsTeamList from '@/molecules/TsTeamList.vue'
	import TsTeachingArchive from '@/organisms/TsTeachingArchive.vue'
	import TsResourcesArchive from '@/organisms/TsResourcesArchive.vue'
	import TsActivitiesArchive from '@/organisms/TsActivitiesArchive.vue'
	import TsSearchResults from '@/molecules/TsSearchResults.vue'

	import TsGallery from '@/molecules/TsGallery.vue'
	import TsPostHeader from '@/molecules/TsPostHeader.vue'
	import TsContentModules from '@/organisms/TsContentModules.vue'
	import TsAdjacentLinks from '@/molecules/TsAdjacentLinks.vue'
	import Ts404Message from '@/organisms/Ts404Message.vue'

	export default {
		name: 'PageView',
		components: {
			MhRouterView,
			TsNewsArchive,
			TsTeamList,
			TsTeachingArchive,
			TsResourcesArchive,
			TsActivitiesArchive,
			TsSearchResults,
			TsGallery,
			TsPostHeader,
			TsContentModules,
			TsAdjacentLinks,
			Ts404Message,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data(){
			return {
				isContentReady  : false,
				post            : undefined,
				newsPosts       : undefined,   // for home
				teamPosts       : undefined,   // for team
				ressourcesPosts : undefined,   // for ressources
				teachingPosts   : undefined,   // for teaching
				activityPosts   : undefined,   // for activity
				searchResults   : undefined,   // for search
			}
		},
		watch: {
			'app.isReady': { // nachdem die app ready ist wird der post geladen
				handler: function( to, from, doLog = false ) {
					if( to ){
						const routeSlug = this.$route.params.slug
						const routeQuery = this.$route.params.query

						if( doLog ){
							console.group( this.$options.name, '• watch: app.isReady')
							console.log('from:', from)
							console.log('to:', to)
							console.log('routeSlug:', routeSlug)
							console.log('routeQuery:', routeQuery)
							console.groupEnd()
						}

						if( routeQuery ){
							this.fetchSearchResults()
						}else{
							this.fetchPost()
						}
					}
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.post ) classes.push( this.$options.name + '--postType-' + this.post.type )

				return classes
			},
			contentModules(){
				const contentModules = this._.get( this.post, 'acf.contentModules' )
				//console.log('contentModules:', contentModules)
				return contentModules
			},
			is404(){
				const isRouteLangValid = this.$route.params.lang === this.$i18n.locale ? true : false
				const isNullPost = this._.isNull( this.post )

				//console.log('isRouteLangValid:', isRouteLangValid)
				//console.log('isNullPost:', isNullPost)

				return !isRouteLangValid || isNullPost
			},
			postSlug(){
				const lang        = this.app.lang
				const routeSlug   = this.$route.params.slug // || this.$route.params.query
				const isHomeTemplateRoute = this.$route.name === 'HomeView' ? true : false

				const homeSlugDe  = this._.get( this.app, 'acfOptions.homepageDe.slug')
				const homeSlugEn  = this._.get( this.app, 'acfOptions.homepageEn.slug')
				const homeSlug    = lang === 'en' && homeSlugEn ? homeSlugEn : homeSlugDe

				const postSlug    = isHomeTemplateRoute ? homeSlug : this.$route.params.slug

				/*
				console.group( this.$options.name, '• postSlug')
				console.log('lang:', lang)
				console.log('routeSlug:', routeSlug)
				console.log('isHomeTemplateRoute:', isHomeTemplateRoute)
				console.log('postSlug:', postSlug)
				console.groupEnd()
				*/

				return postSlug
			},
			postTemplate(){
				const template = this._.get( this.post, 'acf.template' )

				if( this.app.isReady ){
					return template ? template : 'custom'
				}
				else{
					return undefined
				}
			},
			isActivitiesTemplate(){
				return this.$route.params.slug === 'activities' ? true : false
				//return this._.get( this.post, 'acf.template' ) === 'activities' ? true : false
			},
		},
		methods: {
			fetchPost(){ // fetch post or search results
				let postType = 'page'

				if( 'NewsView' === this.$route.name ) postType = 'post'
				if( 'ActivityView' === this.$route.name ) postType = 'activity_post'
				if( 'ResourcesView' === this.$route.name ) postType = 'resources_post'
				if( 'TeachingView' === this.$route.name ) postType = 'teaching_post'

				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						slug : this.postSlug,
						//lang : this.app.lang,
						postType : postType,
					},
					callback : (response) => {
						const result = this._.get( response, 'data.result[0]' )
						this.post = result
						this.isContentReady = true

						if( !result )

						EventBus.$emit('PageView • content ready', this.post)
					},
				})
			},
			fetchSearchResults(){ // fetch search results
				const routeQuery = this.$route.params.query

				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						search : routeQuery,
						lang : this.app.lang,
						perPage : -1,
					},
					callback : (response) => {
						this.searchResults = response.data.result
						this.isContentReady = true

						EventBus.$emit('PageView • content ready', this.post)
					},
				})
			},
		},
		mounted(){
			//console.log('PageView mounted')
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

    .PageView { // debug
        [showBorders2] & {}
    }
    .PageView { // layout
		&__homeIntroText {
			height: 100%;
    	}
    }
    .PageView { // styling
		&--postType-post .view__panel--left,
		&--postType-resources_post .view__panel--left,
		&--postType-teaching_post .view__panel--left,
		&--postType-activity_post .view__panel--left {
			border-right: 2px solid;
			padding: 15px;
			//display: block;

			@media @mq[md] { padding: 13px; }
		}
		&--postType-post .view__panel--right .TsGallery,
		&--postType-resources_post .view__panel--right .TsGallery,
		&--postType-teaching_post .view__panel--right .TsGallery,
		&--postType-activity_post .view__panel--right .TsGallery {
			@media @mq[md] { margin-bottom: 0.5em; }
		}
		.TsAdjacentLinks {
			padding-top: 1em;
		}
    }

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
