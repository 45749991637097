<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsRichText></TsRichText>

	2019-06-08	init

-->

<template>
	<div class="TsRichText contentModule hSpace hSpace--content" :class="elmClasses" data-dev-name="TsRichText">
		<div class="TsRichText__inner contentModule__inner">
			<div class="TsRichText__text richText hyphenate font font--sans font--bold" :class="'font--size' + _.upperFirst( this.fontSize )" v-html="text"></div>
		</div>
		<!--
		<pre name="$attrs">{{$attrs}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'TsRichText',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			text: {
				type     : [String, Boolean],
				default  : false,
			},
			fontSize: {
				type     : [String, Boolean],
				default  : 'default',
			},
			hasIndent: {
				type     : [Boolean],
				default  : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.hasIndent ) classes.push( this.$options.name + '--hasIndent')

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.TsRichText { // debug
		[showBorders2] & {}
	}
	.TsRichText { // layout
	}
	.TsRichText { // styling
		&--hasIndent &__text{ padding-left: 2rem; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
