<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsGallery></TsGallery>

	2019-06-08	init

-->

<template>
	<div class="TsGallery contentModule" :class="elmClasses" v-if="slides" aria-hidden="true" data-dev-name="TsGallery">
		<div class="TsGallery__inner contentModule__inner">
			<VueperSlides class="GmFullscreenMedia__slider no-shadow"
				XXXfade
				transitionSpeed="750"
				duration="3000"
				XXXautoplay
				:dragging-distance="50"
				:infinite="false"
				:touchable="true"
				:pauseOnHover="false"
				:slideRatio="slideRatio"
				:bullets="false"
				:fixedHeight="fixedHeight"
				@ready="onSliderReady"
				@slide="onSlide"
			>
				<template v-slot:arrow-left></template>
				<template v-slot:arrow-right></template>

				<VueperSlide v-for="(image, index) in images" :key="index">
					<template v-slot:content>
						<div class="vueperslide__content">
							<MhImage
								:imageObject="image"
								:mode="'cover'"
							></MhImage>
							<!--
							<span class="debugIndex">{{index}}</span>
							-->
						</div>
					</template>
				</VueperSlide>
			</VueperSlides>
		</div>
		<!--
		<pre name="images">{{images}}</pre>
		<pre name="images.length">{{images.length}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import { VueperSlides, VueperSlide } from 'vueperslides'
	import 'vueperslides/dist/vueperslides.css'
	import MhImage from '@/components/MhImage/MhImage.vue'

	export default {
		name: 'TsGallery',
		components: {
			VueperSlides,
			VueperSlide,
			MhImage,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			images: {
				type     : [Array, Boolean],
				default  : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			slides(){
				return ['a', 'b', 'c']
			},
			slideRatio(){
				const firstImage = this._.isArray( this.images ) ? this.images[0] : null
				const width = this._.get( firstImage, 'width', 1 )
				const height = this._.get( firstImage, 'height', 1 )
				const ratio = height / width

				/*
				console.log('images:', this.images)
				console.log('ratio:', ratio)
				*/

				return ratio
			},
			fixedHeight(){
				return !this.$root.isBelowDt
			},
		},
		methods: {
			handleScroll( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
			onSliderReady( e, doLog = false ){
				if( doLog ){
					console.group( this.$options.name, '• onReady()')
					console.log('e:', e)
					console.groupEnd()
				}
				//this.progressBar.width = 0
				//this.progressBar.transitionDuration = 0

				setTimeout(()=>{
					//this.progressBar.transitionDuration = 5000-500
				}, 50)
				setTimeout(()=>{
					//this.progressBar.width = 100
				}, 100)
			},
			onSlide( e, doLog = false ){
				// group groupCollapsed
				if( doLog ){
					console.group( this.$options.name, '• onSlide()')
					console.log('e:', e)
					console.groupEnd()
				}
				//this.progressBar.width = 0
				//this.progressBar.transitionDuration = 0

				setTimeout(()=>{
					//this.progressBar.transitionDuration = 5000-100
				}, 50)
				setTimeout(()=>{
					//this.progressBar.width = 100
				}, 100)
			},
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.TsGallery { // debug
		[showBorders2] & {}
	}
	.TsGallery { // layout
		height: 100%; @media @mq[md] { height: auto; }
		min-height: 5em; // just to make it visible during dev

		&__inner { height: inherit;	}
	}
	.TsGallery { // styling
		//
	}

	.TsGallery { // vueperslides styling
		@bullet__height  : 1rem;
		@bullet__padding : 0.25rem;
		@bullet__opacity : 0.35;
		@arrow__shadow   : 0px 0px 5px fade(black, 30);
		@bullet__shadow  : 0px 0px 10px fade(black, 30);

		.vueperslides { // main layout
			position: relative;
			height: 100%;
			overflow: hidden;

			&__inner {
				height: inherit;
			}
			&__parallax-wrapper {
				//height: 100vh !important;
				//height: -webkit-fill-available !important;
				height: inherit !important;

				//padding-bottom: 0 !important;
			}
		}
		.vueperslides { // hidden bullets
			&__bullets {
				//background-color: fade( orange, 30 );

				position: absolute;
				left: 0; right: 0;
				bottom: 1.3rem;
				z-index: 2;
				//display: none;

			}
			&__bullet {
				//outline: 1px solid red;
				transition: opacity 0.2s ease;
				display: flex;
				align-items: stretch;

				padding: @bullet__padding;
				height: @bullet__height;
				width: @bullet__height;

				span {
					border-radius: 50%;
					font-size: 0;
					line-height: 0;
					flex-grow: 1;
					background-color: white;
					box-shadow: @bullet__shadow;
				}
			}
			&__bullet--active {
				opacity: 1;
				padding: @bullet__padding * 0.5;

				span {
					background-color: white;
				}
			}
		}
		.vueperslides { // prev + next arrows
			&__arrow {
				//background-color: fade(red, 50);

				position: absolute;
				top: 0; bottom: 0; width: 3em;
				transform: none; // overwrite default styles
				//transition: opacity 0.3s ease;
				//opacity: 1;
				//display: none;

				//@media @mediaQuery__md { display: none; }
				//@media @mediaQuery__sm { display: none; }
			}
			&__arrow--prev,
			&__arrow--next {
				background-repeat: no-repeat;
				background-position: center;
			}
			&__arrow--prev {
				left: 0;
				background-image: url("../assets/images/icon--sliderArrows.svg");
				transform: rotate(180deg);
			}
			&__arrow--next {
				right: 0;
				background-image: url("../assets/images/icon--sliderArrows.svg");
			}
			&:hover .vueperSlides__arrow {
				opacity: 1;
			}
		}
		.vueperslide {  // slide: content wrapper full span height and width incl. MhImage
			position: relative;

			&__content {
				//background-color: fade( cyan, 30 );

				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				height: 100%;
				overflow: hidden;

				display: flex;
				justify-content: center;
				align-items: center;
				//pointer-events: none;
			}
			.MhImage {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				//pointer-events: none;
				//display: none;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
