<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsResourcesArchive></TsResourcesArchive>

	2019-06-08	init

-->

<template>
	<div class="TsResourcesArchive" :class="elmClasses">
		<TsFilterbar
			class="hSpace hSpace--content"
			:taxonomy="'resourcesTag'"
			:posts="posts"
			@setFilter="onSetFilter"
		></TsFilterbar>

		<div class="view__panel view__panel--left">
			<TsGallery
				class="background background--lightBgGray"
				v-if="!$root.isBelowDt"
				:images="_.get( post, 'acf.detailGallery' )"
			></TsGallery>
		</div>
		<div class="view__panel view__panel--right">
			<TsPostHeader
				XXXmainCategories="_.get( post, 'acf.mainCategories' )"
				XXXoverTitle="_.get( post, 'acf.overTitle' )"
				:mainTitle="_.get( post, 'title.rendered' )"
				:postType="_.get( post, 'type' )"
			></TsPostHeader>

			<!--
			<pre name="activeFilter">{{activeFilter}}</pre>
			<pre name="posts">{{posts}}</pre>
			-->

			<ul class="TsResourcesArchive__list reset reset--list hSpace hSpace--content">
				<li class="TsResourcesArchive__listItem" v-for="(post, index) in filteredPosts" :key="index">
					<router-link class="TsResourcesArchive__listItemLink" :to="app.getLinkWithoutHostname( post.link )">
						<span class="TsResourcesArchive__listItemText">
							<span class="TsResourcesArchive__listItemTextTitle block font font--sans font--bold font--sizeLarge">{{_.get( post, 'title.rendered' )}}</span>
						</span>
						<span class="TsResourcesArchive__listItemDots">
							<span
								class="coloredDot font font--sizeLarge"
								v-for="(mainCategory, index) in _.get( post, 'acf.mainCategories' )"
								:key="'d'+index"
								:style="{
									color : _.get( app.getMainCategoryById( mainCategory ), 'acf.color' )
								}"
							></span>
						</span>
					</router-link>
					<span class="line"></span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import TsFilterbar from '@/molecules/TsFilterbar.vue'
	import TsGallery from '@/molecules/TsGallery.vue'
	import TsPostHeader from '@/molecules/TsPostHeader.vue'

	export default {
		name: 'TsResourcesArchive',
		components: {
			TsFilterbar,
			TsGallery,
			TsPostHeader,
		},
		mixins: [
			RestHandler,
		],
		props: {
			post: {
				type     : [Object, Boolean],
				default  : false,
			},
		},
		data(){
			return {
				posts        : undefined,
				isReady      : false,
				activeFilter : [],
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			filteredPosts(){  // filter post by their termIds that matches active filters
				const posts         = this.posts
				let   filteredPosts = []

				this._.forEach( posts, post=>{
					const postTaxonomies = post.taxonomies
					const postTermIds    = []

					this._.forEach( postTaxonomies, (terms, key)=>{
						const termIds = this._.map( terms, 'term_id' )
						//console.log('termIds:', termIds)

						this._.forEach( termIds, (termId)=>{
							if( this.activeFilter.includes( termId) ) filteredPosts.push( post )
							//postTermIds.push( termId )
						})
					})

					//console.log('postTermIds:', postTermIds)
				})

				if( !this.activeFilter.length ) filteredPosts = posts

				return filteredPosts
			},
		},
		methods: {
			fetch(){ // fetch activities
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'resources_post',
						perPage  : -1,
						lang     : this.app.lang,
					},
					callback : (response) => {
						this.posts   = response.data.result
						this.isReady = true
					},
				})
			},
			onSetFilter( e ){
				//console.log('onSetFilter:', e)

				this.activeFilter = e
			},
		},
		created(){},
		mounted(){
			this.fetch()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.TsResourcesArchive { // debug
		[showBorders2] & {}
	}
	.TsResourcesArchive { // layout
		height: 100%;
		width: 100%;

		.TsFilterbar { grid-column: span 4; }

		&__listItemLink {
			display: flex;
			justify-content: space-between;
		}
		&__listItemText {}
		&__listItemDots {
			padding-left: 0.5em;
			display: flex;
		}
	}
	.TsResourcesArchive { // styling
		.coloredDot {
			@media @mq[md] { height: 15px; width: 15px; }
		}

		.TsPostHeader {
			@media @mq[md] { margin-top: 15px; }
		}

		&__listItemLink {
			padding-bottom: 10px;
			color: inherit;

			@media @mq[md] { padding-bottom: 8px; }
		}
		&__listItemTextTitle {
			@media @mq[md] { font-size: 20px; }
		}
		&__listItem + &__listItem &__listItemLink {
			padding-top: 10px;

			@media @mq[md] { padding-top: 8px; }
		}
		&__listItemDots {
			@media @mq[md] { padding-top: 3px; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
