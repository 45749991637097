<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsActivitiesList></TsActivitiesList>

	2019-06-08	init

-->

<template>
	<div class="TsActivitiesList" :class="elmClasses">
		<div class="TsActivitiesList__inner view__panel view__panel--both background background--lightestBgGray">
			<!--
			<pre name="isReady">{{isReady}}</pre>
			-->

			<TsFilterbar
				class="hSpace hSpace--content"
				:taxonomy="'activityTag'"
				:posts="posts"
				@setFilter="onSetFilter"
			></TsFilterbar>

			<!--
			<pre name="activeFilter">{{activeFilter}}</pre>
			<pre name="posts">{{posts}}</pre>
			-->

			<MhMasonryGrid
				class="TsActivitiesList__posts hSpace hSpace--content reset reset--list"
				:gridRowHeight="'10px'"
				:contentSelector="'.TsNewsPostTeaser__inner'"
				:tag="'ol'"
				:doLayout="filteredPosts">
				<TsNewsPostTeaser
					v-for="(newsPost, index) in filteredPosts" :key="index"
					:tag="'li'"
					:post="newsPost"
				></TsNewsPostTeaser>
			</MhMasonryGrid>

			<!--
			<ol class="TsActivitiesList__posts hSpace hSpace--content reset reset--list">
				<TsNewsPostTeaser
					v-for="(post, index) in filteredPosts"
					:key="index"
					:tag="'li'"
					:post="post"
				></TsNewsPostTeaser>
			</ol>
			-->

			<div><br/></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import TsFilterbar from '@/molecules/TsFilterbar.vue'
	import MhMasonryGrid from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhMasonryGrid/v1/MhMasonryGrid.vue'
	import TsNewsPostTeaser from '@/molecules/TsNewsPostTeaser.vue'

	export default {
		name: 'TsActivitiesList',
		components: {
			TsFilterbar,
			MhMasonryGrid,
			TsNewsPostTeaser,
		},
		mixins: [
			RestHandler,
		],
		props: {
			post: { // not used, oder?
				type     : [Object, Boolean],
				default  : false,
			},
		},
		data(){
			return {
				posts        : undefined,
				isReady      : false,
				activeFilter : [],
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			filteredPosts(){  // filter post by their termIds that matches active filters
				const doLog           = false
				const posts           = this.posts
				const activeFilterIds = this.activeFilter
				let   filteredPosts   = []

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• filteredPosts' )
					console.log('posts:', posts)
					console.log('activeFilterIds:', activeFilterIds)
				}

				// walk the posts
				// - collect all term ids
				//
				this._.forEach( posts, post=>{
					const taxonomies = post.taxonomies
					const postTermIds  = []

					if( doLog ) console.log('------')

					// collect all term ids
					this._.forEach( taxonomies, (terms, key)=>{
						const ids = this._.map( terms, 'term_id' )

						ids.forEach( id=>{ postTermIds.push( id ) })
					})

					// check if all activeFilter are matching with the post
					let postMatches = true
					activeFilterIds.forEach( id=>{
						if( !postTermIds.includes( id ) ) postMatches = false
					})

					// take the post if all filters are matching
					if( postMatches ) filteredPosts.push( post )

					if( doLog ) console.log('postTermIds:', postTermIds)
					if( doLog ) console.log('postMatches:', postMatches)
				})

				if( !this.activeFilter.length ) filteredPosts = posts

				if( doLog ){
					console.groupEnd()
				}

				return filteredPosts
			},
		},
		methods: {
			fetch(){ // fetch activities
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'activity_post',
						perPage  : -1,
						lang     : this.app.lang,
					},
					callback : (response) => {
						this.posts   = response.data.result
						this.isReady = true
					},
				})
			},
			onSetFilter( e ){
				//console.log('onSetFilter:', e)

				this.activeFilter = e
			},
		},
		created(){},
		mounted(){
			this.fetch()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.TsActivitiesList { // debug
		[showBorders2] & {}
	}
	.TsActivitiesList { // layout
		height: 100%;
		width: 100%;

		&__posts {
			.grid;
			.grid--gap;

			@media @mq[dt] { .grid--cols-4; }
			@media @mq[md] { .grid--cols-2; }
			@media @mq[sm] { .grid--cols-1; }
		}
	}
	.TsActivitiesList { // styling
		&__posts { padding-top: 20px; padding-bottom: 20px; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
