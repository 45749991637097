<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsFilterbar></TsFilterbar>

	2019-06-08	init

-->

<template>
	<div class="TsFilterbar contentModule background background--lightestBgGray"
		 :class="elmClasses"
		 aria-hidden="true"
		 XXXv-show-slide="_.isArray( categories ) && _.isArray( tags )"
		 data-dev-name="TsFilterbar">
		<div class="TsFilterbar__inner contentModule__inner">
			<!-- all the terms as filter buttons -->
			<div class="TsFilterbar__terms TsFilterbar__terms--isClosed" :class="{'TsFilterbar__terms--isOpen' : barIsOpen}">
				<!-- mainCategories -->
				<a 	class="TsFilterbar__termBtn TsFilterbar__termBtn--category background background--white noSelect font font--sans font--bold"
					:class="{'TsFilterbar__termBtn--isActive' : activeFilter.includes(term.term_id)}"
					:style="getTermBtnStyles( term )"
					v-for="(term) in categories"
					:key="term.term_id"
					@click="doToggleFilter( term.term_id )"
				><span class="TsFilterbar__termBtnLabel">
					{{getTermLabelText( term )}}
					<!-- ({{term.count}}) -->
				</span></a>
				<!-- tags -->
				<a 	class="TsFilterbar__termBtn TsFilterbar__termBtn--tag background background--white noSelect font font--mono"
					:class="{'TsFilterbar__termBtn--isActive' : activeFilter.includes(term.term_id)}"
					v-for="(term) in validTerms"
					:key="term.term_id"
					@click="doToggleFilter( term.term_id )"
				><span class="TsFilterbar__termBtnLabel">{{getTermLabelText( term )}}</span></a>
				<!-- fade out -->
				<div class="TsFilterbar__fader"></div>
			</div>
			<!-- spacer -->
			<!-- fold/unfold terms bar -->
			<a class="TsFilterbar__termBtn TsFilterbar__termBtn--toggleBar noSelect" role="button" @click="barIsOpen = !barIsOpen">
				<span class="TsFilterbar__termBtnLabel"></span>
			</a>
		</div>

		<!--
			<pre name="activeFilter">{{activeFilter}}</pre>
			<pre name="tags">{{tags}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	export default {
		name: 'TsFilterbar',
		components: {},
		mixins: [
			RestHandler,
		],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			posts: {
				type     : [Array],
				default  : ()=>{ return [] },
				required : true,
			},
			taxonomy: {
				type     : [String],
				//default  : '',
				required : true,
			},
		},
		data(){
			return {
				categories   : undefined,
				tags         : undefined,
				activeFilter : [],
				barIsOpen    : false,
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.activeFilter.length ) classes.push( this.$options.name + '--hasActiveFilter')

				return classes
			},
			validTerms(){
				const doLog = false
				const posts = this.posts
				const terms = this.tags || []

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• validTerms' )
					console.log('terms:', terms)
					console.log('posts:', posts)
					console.log('-----')
				}

				// collect all post taxonomy slugs
				const postTaxonomySlugs = []
				posts.forEach( post=>{
					const slugs = this._.map( this._.get( post, 'taxonomies.' + this.taxonomy, [] ), 'slug' )
					if( doLog ) console.log('slugs:', slugs)
					slugs.forEach( slug=>{ postTaxonomySlugs.push( slug ) })
				})

				// filter the tags that are actually given to posts
				const validTerms = []
				terms.forEach( term=>{
					const slug = term.slug

					//if( doLog ) console.log('slug:', slug)

					if( postTaxonomySlugs.includes(slug) ) validTerms.push( term )
				})

				if( doLog ){
					console.log('-----')
					console.log('postTaxonomySlugs:', postTaxonomySlugs)
					console.log('validTerms:', validTerms)
					console.groupEnd()
				}

				return validTerms
			},
		},
		methods: {
			fetchTerms(){ // fetch activities
				//this.terms = []

				this.restHandler__fetch({ // fetch mainCategory
					action : 'GET',
					route : '/wp-json/mh/v1/taxonomyTerms',
					params : {
						slug : 'mainCategory',
					},
					callback : (response) => {
						const result = response.data.result
						/*
						const result = this._.reverse( response.data.result )

						this._.forEach( result, o=>{
							this.terms.unshift( o )
						})
						*/
						this.categories = result
					},
				})
				this.restHandler__fetch({ // fetch activityTag
					action : 'GET',
					route : '/wp-json/mh/v1/taxonomyTerms',
					params : {
						slug : this.taxonomy,
					},
					callback : (response) => {
						const result = response.data.result
						/*
						this._.forEach( result, o=>{
							this.terms.push( o )
						})
						*/
						this.tags = result
					},
				})
			},
			doToggleFilter( termId, doLog = false ){
				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• doToggleAkkordeonItem()' )
					console.log('termId:', termId)
				}

				if( this.activeFilter.includes( termId ) ){
					if( doLog ) console.log('includes > remove:')
					this.activeFilter = this._.without( this.activeFilter, termId )
				}
				else{
					if( doLog ) console.log('!includes > add:')
					this.activeFilter.push( termId )
				}

				if( doLog ){
					console.groupEnd()
				}

				this.$emit( 'setFilter', this.activeFilter )
			},
			getTermLabelText( term ){
				const acfText = this._.get( term, 'acf.' + this.app.lang, term.name )
				return acfText
			},
			getTermBtnStyles( term ){
				const bgColor = this._.get( term, 'acf.color' )
				const lightOrDark = bgColor ? this.lightOrDark( bgColor ) : undefined
				let styles = {}

				// only for mainCategories (they have acf.color )
				if( bgColor ){
					styles.backgroundColor = bgColor
					styles.color = lightOrDark === 'dark' ? 'white' : 'initial'
					styles.content = '"'+lightOrDark+'"'
				}

				return styles
			},
			lightOrDark( color ) {

				// Variables for red, green, blue values
				var r, g, b, hsp;

				// Check the format of the color, HEX or RGB?
				if (color.match(/^rgb/)) {

					// If RGB --> store the red, green, blue values in separate variables
					color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

					r = color[1];
					g = color[2];
					b = color[3];
				}
				else {

					// If hex --> Convert it to RGB: http://gist.github.com/983661
					color = +("0x" + color.slice(1).replace(
					color.length < 5 && /./g, '$&$&'));

					r = color >> 16;
					g = color >> 8 & 255;
					b = color & 255;
				}

				// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
				hsp = Math.sqrt(
				0.299 * (r * r) +
				0.587 * (g * g) +
				0.114 * (b * b)
				);

				// Using the HSP value, determine whether the color is light or dark
				if (hsp>127.5) {

					return 'light';
				}
				else {

					return 'dark';
				}
			},
		},
		created(){},
		mounted(){
			this.fetchTerms()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.TsFilterbar { // debug
		[showBorders3] & {
			background-color: fade( red, 20 );
		}
	}
	.TsFilterbar { // layout
		&__inner {
			display: flex;
		}

		&__terms {
			position: relative;
			flex-grow: 1;
			overflow: hidden;
			white-space: nowrap;
		}
		&__terms--isClosed {
			//max-height: calc( 2em - 2px );
		}
		&__terms--isOpen {
			//max-height: 50vh;
			white-space: initial;
		}
		&__fader {
			position: absolute;
			top: 0; bottom: 0; right: 0;
			width: 7em;
		}
		&__terms--isOpen &__fader {
			display: none;
		}
	}
	.TsFilterbar { // styling
		position: relative;
		z-index: 1; // force shadow to be above
		overflow: hidden; // for slide toggle
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

		&__inner {
			padding-top: 15px;
			padding-bottom: 10px;

			font-size: 16px;
			line-height: 1em;
		}

		&__terms {}
		&__fader {
			//border: 1px solid red;
			/*
			background: linear-gradient(
				90deg,
				@swatches[lightestBgGray] 0%,
				rgba(0,0,0,0) 100%,
			);
			*/
			@colorFrom : fade( @swatches[lightestBgGray], 5 );
			@colorTo   : @swatches[lightestBgGray];

			background: linear-gradient(
				90deg,
				@colorFrom 0%,
				@colorTo 100%,
			);
		}
		&__termBtn {
			display: inline-flex;
			padding: 0em 0.5em;
			margin-right: 0.35em;
			margin-bottom: 0.35em;
			height: 1.5em;
			align-items: center;

			transition: all 0.1s ease;
			border-radius: 0.35em;
			color: inherit;
			white-space: nowrap;
		}
		&__termBtn--tag {
			border: 1px solid;
		}
		&__termBtn--toggleBar {
			//background-color: red;
			position: relative;
			display: inline-flex;
			flex-shrink: 0;
			border: 1px solid;
			border-radius: 50%;
			margin: 0;
			padding: 0;
			width: 1.5em;

			span {
				//background-color: red;
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				border: 4px solid fade(red, 0);
				flex-grow: 1;
				height: 100%;
				background-image: url(./../assets/images/icon--sliderArrows.svg);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				filter: invert(100%);
				transform: rotate(90deg);
			}

			.TsFilterbar__terms--isOpen + & {
				transform: rotate( 180deg );
			}
		}
		&--hasActiveFilter &__termBtn:not(&__termBtn--isActive) {
			opacity: 0.35;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
